<template>
  <div class="CXTestimonial is-full-height has-background-grey-lighter p-a-l p-b-xxl is-flex is-column">
    <heading color="black" size="3">
      {{ testimonial.title }}
    </heading>
    <div class="testimonial__content has-text-black is-expanded">
      "{{ testimonial.content }}"
    </div>
    <div class="testimonial__author m-t-m has-text-grey">
      {{ testimonial.author }}
    </div>
  </div>
</template>

<script>
/**
 * @module CXTestimonial
 */
export default {
  name: 'CXTestimonial',
  props: {
    testimonial: {
      type: Object,
      required: true
    }
  }
}
</script>

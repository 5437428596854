<template>
  <div ref="chart" v-loading="isLoading" class="StreamOnboardingChart" />
</template>

<script>
import { parse } from 'date-fns'
import { OnboardingApiService } from '@/services/OnboardingApiService'
import AreaChart from '@hypefactors/shared/js/components/charts/base/AreaChart'
import { timeseriesDateFormatter } from '@hypefactors/shared/js/components/charts/TooltipFormatter'

/**
 * @module StreamOnboardingChart
 */
export default {
  name: 'StreamOnboardingChart',
  props: {
    query: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      $chart: null,
      chartData: [],
      isLoading: false
    }
  },
  mounted () {
    this.fetchChartData()
  },
  beforeDestroy () {
    this.$chart.destroy()
  },
  methods: {
    async fetchChartData () {
      try {
        this.isLoading = true
        this.chartData = await OnboardingApiService.fetchMentionMetrics({
          keyword: this.query
        })
        this.createChart()
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isLoading = false
      }
    },
    createChart () {
      this.$chart = new AreaChart(
        this.$refs.chart,
        this.createSeries(),
        {
          title: {
            text: this.$t('components.stream_onboarding_chart.title', { count: this.total }),
            style: { color: '#fff', fontWeight: 400, fontSize: '1rem' }
          },
          yAxis: {
            visible: false
          },
          xAxis: {
            visible: false
          },
          chart: {
            // make chart flush
            margin: [30, -3, 5, -3],
            // remove BG
            backgroundColor: ''
          },
          plotOptions: {
            series: {
              enableMouseTracking: false,
              fillOpacity: 0
            }
          },
          tooltipFormatter: timeseriesDateFormatter,
          noData: {
            style: {
              color: '#fff'
            }
          }
        }
      )
      this.$emit('chart-updated')
    },
    createSeries () {
      return [{
        showInLegend: false,
        color: '#fff',
        marker: {
          enabled: false
        },
        name: this.$t('general.mentions'),
        data: this.chartData.map((data) => [parse(data.date).getTime(), data.count])
      }]
    }
  }
}
</script>

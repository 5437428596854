<template>
  <div class="CXTestimonialsSection has-background-info p-v-l">
    <div class="container container--padded">
      <div class="CXTestimonialsSection__Testimonials">
        <div class="columns m-t-none">
          <div
            v-for="(testimonial, index) in testimonials"
            :key="index"
            class="column"
          >
            <c-x-testimonial :testimonial="testimonial" />
          </div>
        </div>
      </div>
      <div class="CXTestimonialsSection__Partners p-v-l">
        <c-x-partner-logos />
      </div>
    </div>
  </div>
</template>

<script>
import CXPartnerLogos from '@/components/cx/pricing/CXPartnerLogos'
import CXTestimonial from '@/components/cx/pricing/CXTestimonial'

/**
 * @module CXTestimonialsSection
 */
export default {
  name: 'CXTestimonialsSection',
  components: { CXTestimonial, CXPartnerLogos },
  computed: {
    testimonials () {
      return [...new Array(2).keys()].map(i => {
        const path = `pages.cx.pricing.testimonials.${i}`
        return {
          title: this.$t(`${path}.title`),
          content: this.$t(`${path}.content`),
          author: this.$t(`${path}.author`)
        }
      })
    }
  }
}
</script>

<template>
  <div class="StreamOnboarding">
    <div
      :style="{ backgroundImage: `url(${$asset('/images/onboarding/stream/background.jpg')})` }"
      class="is-cover-image p-b-xxl"
    >
      <div :class="{ 'min-height-screen': !isOnFirstStep }" class="container container--padded">
        <div class="StreamOnboarding__Navigation has-text-right p-v-xl">
          <img :src="$asset('/images/branding/hf_logo_full_white.svg')" class="is-width-32" alt="">
        </div>
        <div class="m-h-a">
          <div class="columns is-expanded is-variable-grid is-8 is-aligned-top is-aligned-stretched-vertical is-aligned-center">
            <div
              :class="{ 'is-hidden' : !isOnFirstStep }"
              class="column StreamOnboarding__Content StreamOnboarding__Mentions is-12-mobile is-6-tablet"
            >
              <div class="StreamOnboarding__MentionsContainer p-h-l p-v-xl has-background-grey-lighter has-shadow is-full-height">
                <template v-if="isFetchingMentions">
                  <heading size="4">
                    <i18n path="pages.stream_onboarding.fetching_mentions_for">
                      <span slot="stream" class="SteamQueryKeyword">
                        {{ form.stream }}
                      </span>
                    </i18n>
                  </heading>
                  <mention-placeholder class="is-width-full" />
                </template>

                <div
                  v-else-if="mentions.length"
                  @click="focusFirstField"
                >
                  <heading size="4">
                    <i18n path="pages.stream_onboarding.mentions_heading">
                      <span slot="query" class="SteamQueryKeyword">
                        {{ form.stream }}
                      </span>
                    </i18n>
                  </heading>
                  <div
                    v-for="(mention, index) in mentions"
                    :key="index"
                  >
                    <div class="m-b-m">
                      <mention-item-base
                        :content-title="mention.content_title"
                        :media-title-name="mention.media_title_name"
                        :content-body="mention.content_body"
                        :published-at="mention.published_at"
                        :countries="mention.countries"
                        :languages="mention.languages"
                        :presence="mention.presence"
                        :sentiment="mention.sentiment"
                        :type="mention.type"
                        :impressions="mention.impressions"
                        :image="mention.thumbnail"
                        class="has-background-white has-shadow"
                      />
                    </div>
                  </div>
                  <stream-onboarding-chart
                    :query="form.stream"
                    :total="mentionTotals"
                    class="StreamOnboardingChart has-background-primary"
                  />
                </div>

                <div v-else>
                  <heading size="4" class="m-b-xxl">
                    <i18n path="pages.stream_onboarding.no_mentions_for_provided_stream">
                      <span slot="stream" class="SteamQueryKeyword">
                        {{ form.stream }}
                      </span>
                    </i18n>
                  </heading>
                  <heading size="4">
                    {{ $t('pages.stream_onboarding.try_different_query') }}
                  </heading>

                  <form class="field is-grouped m-b-xl" @submit.prevent="handleAlternativeSearch">
                    <p class="control is-expanded">
                      <input
                        v-model="alternativeStreamQuery"
                        class="input"
                        type="text"
                        placeholder="Alternative search term"
                      >
                    </p>
                    <p class="control">
                      <v-button
                        class="is-large is-primary"
                        type="submit"
                      >
                        {{ $t('forms.search') }}
                      </v-button>
                    </p>
                  </form>

                  <heading size="4">
                    {{ $t('pages.stream_onboarding.or_complete_account') }}
                  </heading>
                </div>
              </div>
            </div>
            <div class="column StreamOnboarding__Content StreamOnboarding__Steps is-12-mobile is-6-tablet">
              <el-popover
                v-model="isTooltipVisible"
                :disabled="!isTooltipVisible"
                :content="$t('pages.stream_onboarding.complete_to_see_mentions')"
                popper-class="is-dark"
                trigger="hover"
                width="200"
                placement="right"
              >
                <div slot="reference" class="has-background-grey-lighter has-shadow p-h-l p-v-xl is-full-height">
                  <transition
                    mode="out-in"
                    name="fade"
                  >
                    <router-view
                      ref="steps"
                      v-model="form"
                      :validator="$v.form"
                      :current-step="step"
                      :total-steps="totalSteps"
                      :is-loading="isLoading"
                      @next="goNext"
                      @prev="goPrev"
                    />
                  </transition>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isOnFirstStep">
      <div class="has-background-grey-lighter p-v-xxl">
        <div class="container container--padded">
          <heading size="3">
            {{ $t('pages.stream_onboarding.faq.title') }}
          </heading>
          <div class="columns is-multiline is-variable-grid is-8">
            <div v-for="i in 6" :key="i" class="column is-6">
              <heading :serif="false" size="5">
                {{ $t(`pages.stream_onboarding.faq.questions.${i-1}.question`) }}
              </heading>
              <div class="is-width-3/4-desktop">
                {{ $t(`pages.stream_onboarding.faq.questions.${i-1}.answer`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="has-background-white p-v-xxl">
        <div class="container container--padded">
          <heading size="3">
            {{ $t('pages.stream_onboarding.monitor_stats.title') }}
          </heading>
          <div class="columns has-text-serif">
            <div class="column">
              <div>{{ $t('pages.stream_onboarding.monitor_stats.web_media') }}</div>
              <div class="is-size-2 has-text-weight-semibold">
                2,500,000
              </div>
              <div>{{ $t('pages.stream_onboarding.monitor_stats.pages_per_day') }}</div>
            </div>
            <div class="column">
              <div>{{ $t('general.markets') }}</div>
              <div class="is-size-2 has-text-weight-semibold">
                59
              </div>
              <div>{{ $t('pages.stream_onboarding.monitor_stats.countries_per_day') }}</div>
            </div>
            <div class="column">
              <div>{{ $t('pages.stream_onboarding.monitor_stats.forums') }}</div>
              <div class="is-size-2 has-text-weight-semibold">
                17,800,000
              </div>
              <div>{{ $t('pages.stream_onboarding.monitor_stats.posts_per_day') }}</div>
            </div>
            <div class="column">
              <div>{{ $t('components.media_types.print') }}</div>
              <div class="is-size-2 has-text-weight-semibold">
                20,000
              </div>
              <div>{{ $t('pages.stream_onboarding.monitor_stats.pages_per_day') }}</div>
            </div>
          </div>
        </div>
      </div>
      <c-x-testimonials-section />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

import { Trans } from '@/services/TranslationService'
import { OnboardingApiService } from '@/services/OnboardingApiService'
import { isPasswordStrongEnough } from '@hypefactors/shared/js/utils/validation'

import { RegisterApiService } from '@hypefactors/shared/js/services/api/RegisterApiService'

import MentionItemBase from '@hypefactors/shared/js/components/streams/MentionItemBase'
import MentionPlaceholder from '@/components/placeholders/MentionPlaceholder'
import StreamOnboardingChart from '@/components/streams/StreamOnboardingChart'
import CXTestimonialsSection from '@/components/cx/pricing/CXTestimonialsSection'

const ALTERNATIVE_STREAM = 'Elon Musk'
const FIRST_STEP = 1

/**
 * @module StreamOnboarding
 */
export default {
  name: 'StreamOnboarding',
  components: { CXTestimonialsSection, MentionPlaceholder, MentionItemBase, StreamOnboardingChart },
  data () {
    return {
      step: FIRST_STEP,
      totalSteps: 2,
      isLoading: false,
      isFetchingMentions: false,
      mentions: [],
      mentionTotals: 0,
      // This is used to annotate that the user's stream returned 0 mentions
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        // step 1
        organisation_name: '',
        phone: '',
        role: '',
        // extra meta data
        stream: ALTERNATIVE_STREAM,
        country_iso2: 'US',
        language: 'en'
      },
      isTooltipVisible: false,
      alternativeStreamQuery: ''
    }
  },
  computed: {
    isOnFirstStep () {
      return this.step === FIRST_STEP
    },
    rules () {
      const rules = {
        first_name: {},
        last_name: {},
        email: {},
        password: {},
        // step 1
        organisation_name: {},
        phone: {},
        role: {}
      }
      if (this.step === 1) {
        rules.first_name = { required }
        rules.last_name = { required }
        rules.email = { required, email, emailTaken (email) { return email && OnboardingApiService.IsEmailAvailable(email) } }
      } else if (this.step === 2) {
        rules.organisation_name = { required }
        rules.password = { required, 'password.strength': isPasswordStrongEnough(6) }
        rules.phone = {
          required
          // isValidPhone: (phone, form) => phone && OnboardingApiService.isPhoneValid({ phone, country: form.country_iso2 })
        }
        rules.role = { required }
      }
      return rules
    }
  },
  // set the step on enter
  beforeRouteEnter (to, from, next) {
    let step = to.meta.step
    if (step !== FIRST_STEP) {
      step = FIRST_STEP
      return next({ name: `stream-onboarding.step_${step}`, query: to.query })
    }
    return next(async (vm) => {
      vm.form.stream = to.query.stream || vm.form.stream
      vm.form.email = to.query.email || ''
      vm.form.first_name = to.query.first_name || ''
      vm.form.last_name = to.query.last_name || ''

      try {
        const { country } = await RegisterApiService.getUserIpAndCountry()
        vm.form.country_iso2 = country
        vm.form.language = (to.query.language && Trans.isLangSupported(to.query.language)) ? to.query.language : Trans.getUserSupportedLang()
      } catch (err) { }

      vm.step = step
      vm.fetchMentions()
      vm.fetchCountries()
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.step = to.meta.step
    next()
  },
  validations () {
    return {
      form: this.rules
    }
  },
  methods: {
    ...mapActions(['fetchCountries']),
    goPrev () {
      if (this.step > 1) {
        const step = this.step - 1
        this.$router.push({ name: `stream-onboarding.step_${step}` })
      }
    },
    goNext () {
      if (this.step < this.totalSteps) {
        const step = this.step + 1
        this.$router.push({ name: `stream-onboarding.step_${step}` })
      } else {
        this.submit()
      }
    },
    async fetchMentions () {
      this.isFetchingMentions = true
      try {
        let { mentions, total } = await OnboardingApiService.fetchMentions({
          keyword: this.form.stream
        })
        this.mentions = mentions.slice(0, 2)
        this.mentionTotals = total
      } catch (err) {
        this.$handleError(err)
      } finally {
        this.isFetchingMentions = false
      }
    },
    async submit () {
      this.isLoading = true
      try {
        const response = await OnboardingApiService.registerForMonitor(this.form)
        await this.$store.dispatch('syncUpGlobalAuth', {
          accessToken: response.access_token,
          refreshToken: '' // we dont have it at this endpoint
        })
        this.$ma.trackEvent({
          category: 'free_monitoring_flow',
          action: 'step3',
          label: 'version5'
        }, ['segment', 'facebook'])
        this.$ma.trackEvent({ fb_event: 'CompleteRegistration' }, ['segment', 'ga']) // for facebook
        this.$router.push({ name: 'streams.list', query: { onboarded: true } })
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isLoading = false
      }
    },
    /**
     * This is only for focusing when you click on the mentions
     * It is meant to use the native DOM
     */
    focusFirstField () {
      const elements = this.$refs.steps.$el.querySelectorAll('input.input')
      const firstElement = elements[0]
      if (!firstElement) return
      this.isTooltipVisible = true
      firstElement.focus()
    },

    handleAlternativeSearch () {
      this.form.stream = this.alternativeStreamQuery
      this.alternativeStreamQuery = ''
      this.fetchMentions()
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.StreamOnboarding .SteamQueryKeyword {
  display: inline-block;
  box-decoration-break: clone;
  padding: $padding-tiny $padding-small;
  background: $hf__color-primary;
  color: $hf__color-white;
  font-weight: $hf__font-weight-bold;
}

.StreamOnboarding .StreamOnboardingChart {
  height: 100px
}

@include tablet {
  .StreamOnboarding__Steps {
    position: sticky;
    top: 0;
  }
}
</style>

<template>
  <div class="CXPartnerLogos is-flex can-wrap-mobile is-aligned-middle">
    <div
      v-for="partner in partners"
      :key="partner.image"
      class="is-expanded has-text-centered p-a-m is-width-1/2-mobile"
    >
      <img
        :src="$asset(`/images/cx/pricing/logos/${partner.image}.png`)"
        :alt="partner.name"
      >
    </div>
  </div>
</template>

<script>
/**
 * @module CXPartnerLogos
 */
export default {
  name: 'CXPartnerLogos',
  data () {
    return {
      partners: [
        { image: 'dynaudio', name: 'Dynaudio' },
        { image: 'grupo_casa', name: 'Grupo Casa' },
        { image: 'moet_hennesy', name: 'Moet Hennesy' },
        { image: 'pwc', name: 'PWC' },
        { image: 'radius', name: 'Radius' },
        { image: 'sundogbaelt', name: 'Sund og Baelt' },
        { image: 'vestas', name: 'Vestas' }
      ]
    }
  }
}
</script>

import api from './Api'

export const OnboardingApiService = {
  getUser (userId) {
    return api.get(`activation/${userId}`)
  },
  registerNewsroom (payload) {
    return api.post('/register/newsroom', payload)
  },
  registerForMonitor (payload) {
    return api.postData('/register/monitor', payload)
  },
  IsEmailAvailable (email) {
    return api.post('/register/check-email-taken', { email }).then(() => true).catch((error) => error.response.status !== 422)
  },
  isPhoneValid ({ phone, country }) {
    return api.post('/register/validate-phone-number', { phone, country }).then(() => true).catch((error) => error.response.status !== 422)
  },
  fetchMentions (payload) {
    return api.postData('/register/find-mentions', payload)
  },
  fetchMentionMetrics (payload) {
    return api.postData('/register/find-mentions-metrics', payload)
  }
}
